<template>
  <div>
    <div class="row">
      <div class="form-group col-12">
        <div class="text-center">
          <img
            src="/theme/img/illustrations/lost.svg"
            alt="..."
            class="img-fluid mt--5 mb-4 location-graphic"
            style="max-width: 272px"
          />
        </div>
        <div class="row new-company-toggle">
          <div class="col-12">
            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="col">
                    <label class="mb-1">Link location to {{ activeCompany.name }}?</label>
                    <small class="form-text text-muted" v-if="addToActiveCompany"
                      >The location you add will be associated with {{ activeCompany.name }}.</small
                    >
                    <small class="form-text text-muted" v-if="!addToActiveCompany"
                      >A new company will be created for this location.</small
                    >
                  </div>
                  <div class="col-auto">
                    <!-- <small class="text-muted d-inline-block">{{ addToActiveCompany ? 'Yes' : 'No' }}</small> -->
                    <div class="custom-control custom-checkbox-toggle d-inline-block mt-3">
                      <input
                        v-model="addToActiveCompany"
                        class="custom-control-input"
                        id="toggleOne"
                        type="checkbox"
                      /><label class="custom-control-label" for="toggleOne"></label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <LocationLookup class="text-left" @selectedLocation="selectLocation" />
      </div>
    </div>
  </div>
</template>

<script>
import LocationLookup from '@/components/Modules/Location/LocationLookup/LocationLookup'
import { createNamespacedHelpers } from 'vuex'
import { locationIsClaimed } from '@/lib/modules/location'
const LocationModule = createNamespacedHelpers('location')
const UserModule = createNamespacedHelpers('user')
const CompanyModule = createNamespacedHelpers('company')

export default {
  name: 'AddLocationLookup',
  components: {
    LocationLookup,
  },
  data: () => ({
    isSaving: false,
    addToActiveCompany: true,
  }),
  computed: {
    ...UserModule.mapState(['onlineUser']),
    ...CompanyModule.mapState(['activeCompany']),
    ...CompanyModule.mapState(['companies']),
  },
  methods: {
    ...CompanyModule.mapActions(['setActiveCompany', 'createCompany']),
    ...CompanyModule.mapActions(['refreshCompanyList']),
    ...LocationModule.mapActions(['createLocation', 'setLocationBeingAdded']),

    async selectLocation(location) {
      if (this.isSaving) return
      this.isSaving = true
      let companyId = this.activeCompany._id

      try {
        if (!this.addToActiveCompany) {
          const companyResponse = await this.createCompany({
            userId: this.onlineUser._id,
            location,
            company: {
              name: location.name,
            },
          })
          companyId = companyResponse.body.companyId
        }

        const locationResponse = await this.createLocation({
          userId: this.onlineUser._id,
          companyId: companyId,
          location,
        })

        this.setLocationBeingAdded({
          _id: locationResponse.body.locationId,
          companyId: companyId,
          ...location,
        })

        await this.refreshCompanyList()
        this.setActiveCompany(this.companies[this.companies.length - 1])
        this.$emit('selected')
      } catch (err) {
        locationIsClaimed(this)
      }
      this.isSaving = false
    },
  },
}
</script>

<style scoped lang="scss">
.location-graphic {
  position: relative;
  top: 40px;
}
</style>
