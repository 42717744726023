<template>
  <div>
    <div class="row mb-5">
      <div class="col-12">
        <Alert type="alert-light">
          You'll get
          <strong>
            <span class="text-primary">15 FREE</span>
          </strong>
          table tents that typically last 3 months. Do you want to order any additional survey
          tools?
        </Alert>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-6 mb-5">
        <CustomerTentProductCard />
      </div>
      <div class="col-12 col-md-6 mb-5">
        <CustomerAcrylicUpgradeProductCard />
      </div>
      <div class="col-12 col-md-6 mb-5">
        <CustomerKioskProductCard />
      </div>
      <div class="col-12 col-md-6 mb-5">
        <CustomerBeaconProductCard />
      </div>
      <div class="col-12 mb-5">
        <FadeTransition>
          <button
            v-if="!checkoutAmount"
            @click="skip"
            class="btn btn-lg btn-block btn-primary mt--3"
            :class="{ 'is-loading': isLoading }"
            :disabled="isLoading"
          >
            Continue with 15 table tents
          </button>
        </FadeTransition>
      </div>
    </div>
    <CustomerCheckoutBar @checkedOut="$emit('completed')" />
  </div>
</template>

<script>
// TODO: Extract this whole thang into a seperate component, so it can be used when adding another location
// TODO: Update the checkout bar and this component to dynamically go to another route after toolbelt is finished

import { createNamespacedHelpers } from 'vuex'
const CustomerProductsModule = createNamespacedHelpers('customerProducts')
const UserModule = createNamespacedHelpers('user')
const LocationModule = createNamespacedHelpers('location')
import Alert from '@/components/MainContent/Alert'
import CustomerTentProductCard from '@/components/Modules/Customer/CustomerProducts/CustomerTentProductCard'
import CustomerAcrylicUpgradeProductCard from '@/components/Modules/Customer/CustomerProducts/CustomerAcrylicUpgradeProductCard'
import CustomerKioskProductCard from '@/components/Modules/Customer/CustomerProducts/CustomerKioskProductCard'
import CustomerBeaconProductCard from '@/components/Modules/Customer/CustomerProducts/CustomerBeaconProductCard'
import CustomerCheckoutBar from '@/components/Modules/Customer/CustomerProducts/CustomerCheckoutBar'
import FadeTransition from '@/components/Transitions/FadeTransition'
import OrderService from '@/services/OrderService'
import LocationService from '@/services/LocationService'

export default {
  name: 'LocationToolbelt',
  components: {
    Alert,
    CustomerTentProductCard,
    CustomerAcrylicUpgradeProductCard,
    CustomerKioskProductCard,
    CustomerBeaconProductCard,
    CustomerCheckoutBar,
    FadeTransition,
  },
  computed: {
    ...LocationModule.mapState({ location: 'locationBeingAdded' }),
    ...UserModule.mapState(['onlineUser']),
    ...CustomerProductsModule.mapGetters(['checkoutAmount', 'checkoutItems']),
  },
  data: () => ({
    isLoading: false,
  }),
  methods: {
    async skip() {
      this.isLoading = true

      await OrderService.create({
        userId: this.onlineUser._id,
        locationId: this.location._id,
        items: this.checkoutItems,
      })

      await LocationService.addProducts({
        locationId: this.location._id,
        products: this.checkoutItems.map((item) => item.type),
      })

      this.$emit('completed')
    },
  },
}
</script>

<style scoped lang="scss"></style>
