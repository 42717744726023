<script>
import { createNamespacedHelpers } from 'vuex'
const { mapState, mapMutations } = createNamespacedHelpers('customerProducts')

export default {
  name: 'CustomerProductbase',
  computed: {
    ...mapState(['products']),
  },
  methods: {
    ...mapMutations([
      'incrementProductCounter',
      'decrementProductCounter',
      'selectProduct',
      'removeProduct',
    ]),
  },
}
</script>
