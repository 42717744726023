<template>
  <LocationReputation :location="location" @complete="completed" />
</template>

<script>
import LocationReputation from '@/components/Modules/Location/LocationReputation/LocationReputation'
import { createNamespacedHelpers } from 'vuex'
const { mapState } = createNamespacedHelpers('location')

export default {
  name: 'AddLocationReputation',
  components: {
    LocationReputation,
  },
  computed: {
    ...mapState({ location: 'locationBeingAdded' }),
  },
  methods: {
    completed() {
      this.$emit('completed')
    },
  },
}
</script>

<style scoped lang="scss"></style>
