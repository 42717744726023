<template>
  <div class="header">
    <div class="header-body">
      <div class="row align-items-center">
        <div class="col-auto">
          <div class="avatar avatar-lg">
            <img
              :src="activeLocation.primaryPhoto || '/img/avatars/profiles/store.svg'"
              class="avatar-img rounded"
            />
          </div>
        </div>
        <div class="col-auto">
          <h6 class="header-pretitle">
            <router-link :to="{ name: 'company-settings-locations' }">
              Locations <i class="fe fe-chevron-right"></i>
            </router-link>
          </h6>
          <h1 class="header-title">
            {{ activeLocation.friendlyName || activeLocation.city }}
          </h1>
        </div>
      </div>
      <LocationSettingsNavbar />
    </div>
  </div>
</template>

<script>
import LocationSettingsBase from './LocationSettingsBase'
import LocationSettingsNavbar from './LocationSettingsNavbar'

export default {
  name: 'LocationSettingsHeader',
  extends: LocationSettingsBase,
  components: {
    LocationSettingsNavbar,
  },
}
</script>

<style scoped lang="scss"></style>
