<template>
  <ul class="list-group list-group-lg list-group-flush list my--4">
    <li
      @click="selectLocation(location)"
      v-for="(location, idx) in locationResults"
      :key="idx"
      class="list-group-item px-0"
    >
      <div class="row align-items-center">
        <div class="col-auto">
          <span class="avatar avatar-lg">
            <img
              :src="location.primaryPhoto || '/img/avatars/profiles/store.svg'"
              class="avatar-img rounded"
            />
          </span>
        </div>
        <div class="col ml--2">
          <h4 class="card-title mb-1 name">
            {{ location.name }}
          </h4>
          <p v-if="location.address" class="card-text small text-muted mb-1">
            {{ location.address }}
          </p>
          <p v-if="location.bandwidth?.phone" class="card-text small text-muted">
            {{ location.bandwidth.phone }}
          </p>
        </div>
        <div class="col-auto">
          <i class="fe fe-chevron-right icon-list-selection text-muted"></i>
        </div>
      </div>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'LocationLookupResults',
  props: ['locationResults'],
  methods: {
    selectLocation(location) {
      this.$emit('selectedLocation', location)
      this.$emit('increaseProgress')
    },
  },
}
</script>

<style scoped lang="scss">
.list-group-item {
  &:hover {
    cursor: pointer;

    .card-title {
      color: var(--blue);
    }
  }
}
</style>
