import { render, staticRenderFns } from "./AddLocationReputation.vue?vue&type=template&id=54130a07&scoped=true"
import script from "./AddLocationReputation.vue?vue&type=script&lang=js"
export * from "./AddLocationReputation.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "54130a07",
  null
  
)

export default component.exports