<template>
  <LocationToolbelt @completed="complete" />
</template>

<script>
import LocationToolbelt from '@/components/Modules/Location/LocationToolbelt'

export default {
  name: 'AddLocationToolbelt',
  components: {
    LocationToolbelt,
  },
  methods: {
    complete() {
      this.$router.push({ name: 'company-settings-locations' })
    },
  },
}
</script>

<style scoped lang="scss"></style>
