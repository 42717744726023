<template>
  <div class="input-group input-group-flush input-group-merge">
    <input
      ref="query"
      v-model="query"
      @keydown="startSpinner"
      @keyup="search"
      type="search"
      class="form-control form-control-prepended search location-search"
      placeholder="What's your business name or address?"
    />
    <div class="input-group-prepend">
      <div class="input-group-text">
        <span class="fe fe-search"></span>
      </div>
    </div>
    <div class="input-group-append">
      <div class="input-group-text">
        <FadeTransition>
          <div v-if="isSearching" class="loader loader-sm loader-primary"></div>
        </FadeTransition>
      </div>
    </div>
  </div>
</template>

<script>
import { debounce } from 'lodash-es'
import { createNamespacedHelpers } from 'vuex'
const { mapState, mapMutations } = createNamespacedHelpers('location')
import FadeTransition from '@/components/Transitions/FadeTransition'

export default {
  name: 'LocationLookupCard',
  components: {
    FadeTransition,
  },
  computed: {
    ...mapState(['searchQuery', 'isSearching']),
    query: {
      set(query) {
        this.setSearchQuery(query)
      },
      get() {
        return this.searchQuery
      },
    },
  },
  methods: {
    ...mapMutations(['setSearchQuery', 'setIsSearching']),
    startSpinner() {
      this.setIsSearching(true)
    },
    search: debounce(function () {
      this.$emit('increaseProgress')
      this.$emit('searched')
    }, 1000),
  },
  mounted() {
    this.$refs.query.focus()
  },
}
</script>

<style scoped lang="scss"></style>
