<template>
  <div class="row align-items-center">
    <div class="col">
      <ul class="nav nav-tabs nav-overflow header-tabs">
        <li class="nav-item" v-for="(navItem, idx) in navItems" :key="idx">
          <router-link
            :to="{ name: navItem.route }"
            class="nav-link"
            :class="{ active: navItem.route === activeSettingsTab }"
            >{{ navItem.title }}</router-link
          >
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import LocationSettingsBase from './LocationSettingsBase'
import { mapState } from 'vuex'

export default {
  name: 'LocationSettingsNavbar',
  extends: LocationSettingsBase,
  computed: {
    ...mapState('location', ['activeSettingsTab']),
    ...mapState('navigation', {
      navItems: 'locationNavigation',
    }),
  },
}
</script>

<style scoped lang="scss"></style>
