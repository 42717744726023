<template>
  <div class="container">
    <div class="row justify-content-center">
      <div v-if="initialized" class="col-12">
        <LocationSettingsHeader />
        <SlideFadeTransition>
          <router-view />
        </SlideFadeTransition>
      </div>
    </div>
  </div>
</template>

<script>
import LocationSettingsHeader from './LocationSettingsHeader'
import LocationSettingsBase from './LocationSettingsBase'
import SlideFadeTransition from '@/components/Transitions/SlideFadeTransition'
import LocationService from '@/services/LocationService'
import { surveyQuestions, surveyDelays } from '@/lib/modules/location'
import { createNamespacedHelpers } from 'vuex'
const { mapMutations } = createNamespacedHelpers('location')

export default {
  name: 'LocationSettings',
  extends: LocationSettingsBase,
  components: {
    LocationSettingsHeader,
    SlideFadeTransition,
  },
  data: () => ({
    initialized: false,
  }),
  methods: {
    ...mapMutations(['setActiveLocation']),
    async getActiveLocations() {
      const response = await LocationService.get(this.$route.params.locationId)
      const { location } = response.body

      this.setActiveLocation({
        ...location,
        settings: location.settings || {
          surveyQuestion: surveyQuestions[0],
          surveyDelay: surveyDelays[0],
        },
      })
      this.$root.$emit('activeLocationUpdated')
      this.initialized = true
    },
  },
  async mounted() {
    this.$root.$on('getLocationData', () => {
      this.getActiveLocations()
    })
    const response = await LocationService.get(this.$route.params.locationId)
    const { location } = response.body

    this.setActiveLocation({
      ...location,
      settings: location.settings || {
        surveyQuestion: surveyQuestions[0],
        surveyDelay: surveyDelays[0],
      },
    })
    this.initialized = true
  },
}
</script>

<style scoped lang="scss"></style>
