<template>
  <SlideFadeTransition>
    <AddLocationLookup
      v-if="flowStep === 'lookup'"
      v-on:increaseProgress="increaseProgress()"
      @selected="selectedLocation"
    />
    <AddLocationReputation v-if="flowStep === 'reputation'" @completed="completedReputation" />
  </SlideFadeTransition>
</template>

<script>
import AddLocationLookup from './AddLocationLookup'
import AddLocationReputation from './AddLocationReputation'
import AddLocationToolbelt from './AddLocationToolbelt'
import SlideFadeTransition from '@/components/Transitions/SlideFadeTransition'

export default {
  name: 'AddLocationContent',
  components: {
    AddLocationLookup,
    AddLocationReputation,
    AddLocationToolbelt,
    SlideFadeTransition,
  },
  data: () => ({
    flowStep: 'lookup',
  }),
  methods: {
    selectedLocation() {
      this.flowStep = 'reputation'
    },
    completedReputation() {
      this.$router.push({ name: 'company-settings-locations' })
    },
    increaseProgress(amount) {
      let amountToIncrease = amount ? amount : 3
    },
  },
}
</script>
