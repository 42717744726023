<template>
  <CustomerProductCard :product="products.tent" />
</template>

<script>
import CustomerProductCard from './CustomerProductCard'
import CustomerProductBase from './CustomerProductBase'

export default {
  name: 'CustomerTentProductCard',
  extends: CustomerProductBase,
  components: {
    CustomerProductCard,
  },
}
</script>

<style scoped lang="scss"></style>
