<template>
  <div class="comment mb-4">
    <div class="row">
      <div v-if="side === 'left'" class="col-auto">
        <span class="avatar">
          <img
            :src="review.photo || '/theme/img/avatars/profiles/avatar-2.jpg'"
            class="avatar-img rounded-circle"
          />
        </span>
      </div>
      <div class="col ml--2">
        <div class="comment-body">
          <div class="row">
            <div class="col">
              <h5 class="comment-title">
                <span class="mr-2">{{ review.author }}</span>
                <StarRating :rating="review.rating" />
              </h5>
            </div>
            <div class="col-auto">
              <time class="comment-time">
                {{ review.time }}
              </time>
            </div>
          </div>
          <p v-if="review.text" class="comment-text">
            {{ review.text | truncate(170) }}
          </p>
        </div>
      </div>
      <div v-if="side === 'right'" class="col-auto">
        <span class="avatar">
          <img
            :src="review.photo || '/theme/img/avatars/profiles/avatar-2.jpg'"
            class="avatar-img rounded-circle"
          />
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import StarRating from '@/components/Modules/Review/StarRating'

export default {
  name: 'SignupReputationReview',
  props: ['side', 'review'],
  components: {
    StarRating,
  },
}
</script>

<style scoped lang="scss">
.comment-body {
  background: #fff;
}
</style>
