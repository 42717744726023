<template>
  <CustomerProductCard :product="products.acrylic" />
</template>

<script>
import CustomerProductCard from './CustomerProductCard'
import CustomerProductBase from './CustomerProductBase'

export default {
  name: 'CustomerAcrylicUpgradeProductCard',
  extends: CustomerProductBase,
  components: {
    CustomerProductCard,
  },
}
</script>

<style scoped lang="scss"></style>
