<template>
  <div class="card">
    <div class="card-body text-center">
      <span class="card-avatar card-avatar-top">
        <img :src="product.img" height="75" :alt="product.title" />
      </span>

      <h2 class="card-title">
        <span>{{ product.title }}</span>
      </h2>

      <p class="card-text text-muted">
        <small>
          {{ product.subTitle }}
          <span
            v-if="product.badge.inline"
            class="badge mt--1"
            :class="['badge-soft-' + product.badge.type]"
            >{{ product.badge.text }}</span
          >
        </small>
      </p>

      <p v-if="product.price" class="card-text">
        <span>
          {{ product.price.amount | currency }}
          <span class="text-muted">{{ product.price.description }}</span>
        </span>
      </p>

      <p v-if="!product.badge.inline" class="card-text">
        <span class="badge mt--1" :class="['badge-soft-' + product.badge.type]">{{
          product.badge.text
        }}</span>
      </p>

      <hr />

      <div class="row align-items-center justify-content-center">
        <div v-if="product.counter" class="col-auto">
          <button @click="decrementProductCounter(product.type)" class="btn btn-sm btn-white">
            <span class="fe fe-minus text-primary"></span>
          </button>
          &nbsp;
          <button class="btn btn-sm btn-white">
            {{ product.counter.count }}
            <span class="text-muted"
              >({{
                ((product.counter.count - product.counter.default) * product.price.amount)
                  | currency
              }})</span
            >
          </button>
          &nbsp;
          <button @click="incrementProductCounter(product.type)" class="btn btn-sm btn-white">
            <span class="fe fe-plus text-default"></span>
          </button>
        </div>
        <div v-if="product.button && !product.added" class="col-auto">
          <button
            @click="selectProduct(product.type)"
            class="btn btn-sm buy-button"
            :class="['btn-' + product.button.type]"
          >
            {{ product.button.text }}
          </button>
        </div>
        <div v-if="product.added" class="col-auto">
          <button
            @click="removeProduct(product.type)"
            class="btn btn-sm btn-primary buy-button btn-success btn-rounded-circle"
          >
            <span class="fe fe-check"></span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CustomerProductBase from './CustomerProductBase'
export default {
  name: 'CustomerProductCard',
  extends: CustomerProductBase,
  props: ['product'],
}
</script>

<style scoped lang="scss"></style>
