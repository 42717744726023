<template>
  <div class="text-center">
    <div class="card">
      <div class="card-header">
        <div class="row">
          <div class="col-12">
            <LocationLookupInput
              @searched="searchLocations"
              v-on:increaseProgress="$emit('increaseProgress')"
            />
          </div>
        </div>
      </div>
      <div v-if="locationResults.length" class="card-body">
        <LocationLookupResults
          :locationResults="locationResults"
          @selectedLocation="selectedLocation"
          v-on:increaseProgress="$emit('increaseProgress')"
        />
      </div>
    </div>
    <button class="btn btn-link btn-light text-muted btn-sm" @click="manualEntry">
      Use advanced mode
    </button>
  </div>
</template>

<script>
import LocationLookupInput from './LocationLookupInput'
import LocationLookupResults from './LocationLookupResults'
import { createNamespacedHelpers } from 'vuex'
import GoogleService from '@/services/GoogleService'
const { mapState, mapMutations } = createNamespacedHelpers('location')

export default {
  name: 'LocationLookup',
  components: {
    LocationLookupInput,
    LocationLookupResults,
  },
  data: () => ({
    locationResults: [],
  }),
  computed: {
    ...mapState(['searchQuery', 'isSearching']),
  },
  methods: {
    ...mapMutations(['setIsSearching']),
    /**
     * Calls the google places API to find the business
     */
    async searchLocations() {
      if (!this.searchQuery) {
        this.locationResults = []
        this.setIsSearching(false)
        return
      }

      this.setIsSearching(true)

      this.locationResults = await GoogleService.locationSearch({
        input: this.searchQuery,
        types: ['establishment'],
        regions: {
          country: 'us',
        },
      })

      this.setIsSearching(false)
    },
    async manualEntry() {
      var placeId = window.prompt('Enter a Google Place ID.')
      try {
        const location = await GoogleService.locationDetails(placeId)
        this.selectedLocation(location)
      } catch (error) {
        window.alert('Error using that Place ID')
      }
    },
    /**
     * Selects the business and moves onto the next signup step
     */
    selectedLocation(location) {
      this.$emit('selectedLocation', location)
    },
  },
}
</script>

<style scoped lang="scss"></style>
