/*global google*/

// import Vue from 'vue'
const placesSearch = new google.maps.places.AutocompleteService()
const sessionToken = new google.maps.places.AutocompleteSessionToken()
const placesService = new google.maps.places.PlacesService(document.createElement('div'))

export default {
  // locationSearch(payload) {
  //   return Vue.http.post('google/location-search', payload)
  // }
  /**
   * Returns a list of locations based off the request `input`
   */
  locationSearch(request) {
    return new Promise((resolve) => {
      placesSearch.getPlacePredictions(
        { ...request, sessionToken },
        async (predictions, status) => {
          if (status != google.maps.places.PlacesServiceStatus.OK) {
            return resolve([])
          }

          const locationsList = predictions
            .filter((prediction) => prediction.place_id)
            .map((prediction) => ({
              name: prediction.structured_formatting.main_text,
              placeId: prediction.place_id,
            }))

          const locations = await Promise.all(
            locationsList.map((location) => this.locationDetails(location.placeId))
          )

          resolve(locations.filter((l) => l))
        }
      )
    })
  },
  /**
   * Returns location details based off a Google placeId
   */
  locationDetails(placeId) {
    return new Promise((resolve) => {
      placesService.getDetails(
        {
          placeId,
          fields: [
            'name',
            'rating',
            'formatted_phone_number',
            'formatted_address',
            'place_id',
            'photos',
            'reviews',
          ],
        },
        (place, status) => {
          if (status !== google.maps.places.PlacesServiceStatus.OK) {
            return null
          }

          const photos = (place.photos || []).map((photo) => photo.getUrl())
          const reviews = (place.reviews || []).map((review) => ({
            author: review.author_name,
            rating: review.rating,
            photo: review.profile_photo_url,
            time: review.relative_time_description,
            text: review.text,
          }))

          resolve({
            name: place.name,
            placeId: place.place_id,
            phone: place.formatted_phone_number,
            reviews,
            photos,
            primaryPhoto: photos[0],
            rating: place.rating,
            address: place.formatted_address,
          })
        }
      )
    })
  },
}
