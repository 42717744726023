<template>
  <MainContent>
    <LocationSettings />
  </MainContent>
</template>

<script>
import MainContent from '@/components/MainContent/MainContent'
import LocationSettings from '@/components/Modules/Location/LocationSettings/LocationSettings'
import { trackEvent } from '@/lib/analytics'

export default {
  name: 'LocationSettingsPage',
  components: {
    MainContent,
    LocationSettings,
  },
  mounted() {
    trackEvent(this.$intercom, 'Location settings')
  },
}
</script>

<style scoped lang="scss"></style>
