<template>
  <SlideUpTransition>
    <nav v-if="checkoutAmount" class="navbar navbar-light fixed-bottom shadow-lg">
      <div class="col-12">
        <div class="row align-items-center justify-content-between">
          <div class="col-auto">
            <span class="navbar-brand">
              <span class="text-muted">Total</span>
              {{ checkoutAmount | currency }}
            </span>
          </div>
          <div class="col-auto">
            <button
              @click="checkout"
              class="btn btn-primary"
              :class="{ 'is-loading': checkingOut }"
              :disabled="checkingOut"
            >
              Checkout
            </button>
          </div>
        </div>
      </div>
    </nav>
  </SlideUpTransition>
</template>

<script>
/*global StripeCheckout*/

import Config from '@/config'
import { createNamespacedHelpers } from 'vuex'
import { bootIntercom, shutdownIntercom } from '@/lib/analytics'
import OrderService from '@/services/OrderService'
import LocationService from '@/services/LocationService'
import CompanyService from '@/services/CompanyService'
import SlideUpTransition from '@/components/Transitions/SlideUpTransition'
const CustomerProductsModule = createNamespacedHelpers('customerProducts')
const UserModule = createNamespacedHelpers('user')
const LocationModule = createNamespacedHelpers('location')

export default {
  name: 'CustomerCheckoutBar',
  components: {
    SlideUpTransition,
  },
  computed: {
    ...LocationModule.mapState({ location: 'locationBeingAdded' }),
    ...UserModule.mapState(['onlineUser']),
    ...UserModule.mapGetters(['brandName']),
    ...CustomerProductsModule.mapGetters([
      'checkoutAmount',
      'checkoutItems',
      'checkoutDescription',
    ]),
  },
  data: () => ({
    checkingOut: false,
  }),
  methods: {
    checkout() {
      this.checkingOut = true
      this.openStripeCheckout()
    },
    openStripeCheckout() {
      const { stripe: stripeKey } = Config

      const handler = StripeCheckout.configure({
        key: stripeKey,
        locale: 'auto',
        closed: () => {
          this.checkingOut = false
        },
        token: this.finishCheckout,
      })

      // Open Checkout with further options:
      handler.open({
        name: this.brandName,
        email: this.onlineUser.email,
        description: this.checkoutDescription,
        allowRememberMe: true,
        zipCode: true,
        amount: this.checkoutAmount * 100,
      })
    },
    async finishCheckout(token) {
      await OrderService.create({
        userId: this.onlineUser._id,
        locationId: this.location._id,
        stripeToken: token.id,
        amount: this.checkoutAmount,
        items: this.checkoutItems,
      })

      await LocationService.addProducts({
        locationId: this.location._id,
        products: this.checkoutItems.map((item) => item.type),
      })

      await CompanyService.saveCard({
        companyId: this.location.companyId,
        token,
      })

      this.checkingOut = false
      this.$emit('checkedOut')
    },
  },
  watch: {
    checkoutAmount: (newAmount) => {
      if (newAmount > 0) return shutdownIntercom(this.$intercom)
      return bootIntercom(this.$intercom)
    },
  },
}
</script>

<style scoped lang="scss">
.navbar {
  background: #fff;
}
</style>
