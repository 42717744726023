<template>
  <div class="row">
    <transition-group name="comments" tag="div">
      <LocationReputationReview
        v-for="(review, idx) in reviews"
        :key="review.time"
        :side="idx % 2 === 0 ? 'left' : 'right'"
        :review="review"
        class="col-12"
      />
    </transition-group>
  </div>
</template>

<script>
import LocationReputationReview from './LocationReputationReview'

export default {
  name: 'LocationReputation',
  props: ['location'],
  components: {
    LocationReputationReview,
  },
  data: () => ({
    reviews: [],
  }),
  async mounted() {
    const wait = (time = 1000) => new Promise((resolve) => setTimeout(resolve, time))

    if (this.location && this.location.reviews.length) {
      for (let i = 0; i < this.location.reviews.length; i++) {
        await wait()
        this.$emit('increaseProgress')
        this.reviews.push(this.location.reviews[i])
      }
    }

    await wait(2000)
    this.$emit('complete')
  },
}
</script>

<style scoped lang="scss">
.comments-enter-active,
.comments-leave-active {
  transition: all 1s;
}
.comments-enter,
.comments-leave-to {
  opacity: 0;
  transform: translateY(30px);
}
</style>
