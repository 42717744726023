<template>
  <CustomerProductCard :product="products.beacon" @selectedProduct="joinWaitlist" />
</template>

<script>
import CustomerProductCard from './CustomerProductCard'
import CustomerProductBase from './CustomerProductBase'

export default {
  name: 'CustomerBeaconProductCard',
  extends: CustomerProductBase,
  components: {
    CustomerProductCard,
  },
  methods: {
    joinWaitlist() {
      this.$notify({
        title: 'Congrats!',
        text: "You've been added to the waitlist.",
      })
    },
  },
}
</script>

<style scoped lang="scss"></style>
