<template>
  <MainContent>
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-12 col-lg-10">
          <AddLocationHeader />
        </div>
        <div class="col-12 col-md-10 col-xl-6 col-lg-6">
          <AddLocationContent />
        </div>
      </div>
    </div>
  </MainContent>
</template>

<script>
import MainContent from '@/components/MainContent/MainContent'
import AddLocationHeader from '@/components/Modules/Location/AddLocation/AddLocationHeader'
import AddLocationContent from '@/components/Modules/Location/AddLocation/AddLocationContent'
import { trackEvent } from '@/lib/analytics'

export default {
  name: 'AddLocationPage',
  components: {
    MainContent,
    AddLocationHeader,
    AddLocationContent,
  },
  mounted() {
    trackEvent(this.$intercom, 'Add another location')
  },
}
</script>

<style scoped lang="scss"></style>
